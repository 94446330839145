import React, { Component } from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

class UiP extends Component {
  render() {
    const nav = {
      initialSlideIndex: 1,
    };
    const lang = "pl";
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #9B394A, #AE3F53, #D24E65, #AE3F53, #9B394A )",
          icons: "#D24E65",
          navClass: "uip",
        }}
        seo={{
          title: "Uwodzenie i podryw",
          headerTitle: "uip",
          href: slug,
          lang: "pl",
          ogImage: require("../../assets/img/portfolio/uip_top_back.jpg"),
        }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/projects/uwodzenie-i-podryw/",
        }}
      >
        <PortfolioHeader name="uip" height="252" />
        <section className="container-fluid uip_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Uwodzenie i podryw</h1>
                <ul>
                  <li>Strona internetowa</li>
                  <li>Banery reklamowe</li>
                  <li>Kampania leadowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner">
                <p>
                  Z rekomendacji jednego z naszych klientów z którym
                  realizowaliśmy duży projekt wizerunkowo sprzedażowy, zgłosił
                  się do nas znany ekspert do spraw relacji damsko-męskich,
                  Rafał Żuber. Naszym zadaniem było stworzenie narzędzi
                  biznesowych, które pozwoliłyby naszemu Klientowi zostać
                  liderem w swojej branży, zarówno pod kątem wizerunkowym jak i
                  wyników finansowych.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_3">
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("../../assets/img/portfolio/Audyt_UiP.jpg")}
                alt=""
                className="img-fluid mockup"
              />
            </div>
            <div className="col-md-5 offset-md-1 col">
              <div className="inner">
                <h2>
                  Uczymy się na błędach... <br></br>
                  .....jednak najlepiej na cudzych
                </h2>
                <p>
                  Na potrzeby realizacji projektu przeprowadziliśmy audyt
                  branżowy a wynik prac przedstawiliśmy w liczącym kilkadziesiąt
                  stron raporcie. Raport ten był jednocześnie podstawą do
                  dalszej pracy nad modelem biznesowym klienta oraz makietami
                  serwisu.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_4">
          <div className="row no-gutters">
            <div className="col-md-5 offset-md-1 text">
              <div className="inner">
                <h2>Konsultacje z klientem</h2>
                <p>
                  W ramach współpracy przeszkoliliśmy naszego klienta z
                  planowania i rozrysowania procesów biznesowych oraz wdrożenia
                  rozwiązań typu marketing automation w celu przekonwertowania
                  użytkowników portalu na stałych klientów.
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 image_holder">
              <img
                src={require("../../assets/img/portfolio/Procesess_UiP.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_5">
          <div className="row row_1">
            <div className="col-md-5">
              <img
                src={require("../../assets/img/portfolio/Makieta_UiP.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-5 offset-md-1 col_1">
              <div className="inner">
                <h2>Makiety</h2>
                <p>
                  Finalny kształt i mechanika portalu została wypracowana dzięki
                  bardzo zaawansowanemu prototypowaniu. Bardzo dokładnie zostały
                  określone cele jak ma realizować serwis, dużo czasu
                  poświęciliśmy więc na zaplanowanie przepływu użytkowników oraz
                  droga do realizowania przez nich celów zdefiniowanych w
                  założeniach biznesowych. Kluczowym aspektem realizacji była
                  sprzedaż, zadbaliśmy więc o odpowiednie mechanizmy cross/up
                  sellingowe.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_6">
          <div className="row">
            <div className="col-md-5 offset-md-1 col_2">
              <div className="inner">
                <h2>Strona internetowa</h2>
                <p>
                  Dzięki rebrandingowi, marka Uwodzenie i Podryw w zasadzie z
                  miejsca stała się liderem swojej branży. Nowy serwis
                  internetowy uzupełnił proces zmiany wizerunku zapewniając
                  jednocześnie zdecydowaną poprawę wskaźników sprzedaży, dzięki
                  postawieniu na zaawansowaną obsługę mechanizmów biznesowych i
                  wsparcia sprzedaży - UX i Design naprawdę mają znaczenie:)
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="scroll_wrapper">
                <span />
                <div className="img_wrapper">
                  <img
                    src={require("../../assets/img/portfolio/UiP_page.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_7">
          <div className="row">
            <div className="col-md-5 img-holder">
              <img
                className="img-fluid image1"
                src={require("../../assets/img/portfolio/Site1_UiP.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1 col_3">
              <div className="inner">
                <p>
                  Zadbaliśmy również o wizerunkowy update starszych materiałów
                  marketingowych, które dostosowaliśmy do nowej odsłony marki
                  UiP oraz przygotowaliśmy strategię dalszych działań
                  wizerunkowych.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid uip_section_8">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <img
                className="img-fluid image2"
                src={require("../../assets/img/portfolio/Site2_UiP.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <img
                src={require("../../assets/img/portfolio/Kampania_UiP.png")}
                alt=""
                className="img-fluid image3"
              />
            </div>
          </div>
        </div>

        <section className="container-fluid uip_section_9">
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("../../assets/img/portfolio/Youtube_UiP.png")}
                alt=""
                className="img-fluid yt"
              />
            </div>
            <div className="col-md-5 offset-md-1 text_holder">
              <div className="inner">
                <h2>Kampania</h2>
                <p>
                  Przeprowadziliśmy kampanię sprzedażową, przygotowując również
                  materiały takie jak banery czy strony lądowania. Nasz klient
                  został również przeszkolony z zakresu samodzielnego promowania
                  produktów oraz samego siebie (jako nieodłącznej części marki
                  UiP) w mediach społecznościowych oraz uwzględnienia tych
                  elementów w procesach planowania swojego biznesu oraz
                  realizowania kolejnych strategii produktowych.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_10">
          <div className="container">
            <h2>Referencje dla tego projektu</h2>
            <div className="content">
              <img
                src={require("../../assets/img/portfolio/portret_UiP.png")}
                alt=""
                className="img-fluid"
              />

              <p>
                Moja pierwsza strona internetowa powstała w 2008 roku. Od tego
                czasu pracowałem z wieloma firmami i nierzadko miałem pretensje
                do jakości obsługi. Kiedy pierwszy raz połączyłem się
                telefonicznie z Panem Łukaszem Pachem wiedziałem, że zawiążemy
                współpracę. W rozmowie telefonicznej dało się odczuć, że firma
                ADream stawia na pomoc człowiekowi, a nie przede wszystkim na
                zarobek. To przekonało mnie do współpracy.
              </p>
            </div>
            <div className="first-btn">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://adream.pl/referencje/joy-for-the-people-rafal-zuber";
                }}
              >
                {" "}
                Zobacz więcej
              </button>
            </div>
          </div>
        </section>
        {/* <section className="container-fluid uip_section_7">
            <div className="row">
            <div className="col-md-6 text-right">


            </div>
         <div className="col-md-5 offset-md-1">
         <img
                src={require("../../assets/img/portfolio/Kampania_UiP.png")}
                alt=""
                />
                </div>
             </div>
        </section> */}

        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default UiP;
